import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { PRICE_CALCULATOR } from "./urls";

const GuestRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Routes>
      <Route
        index
        {...rest}
        element={
          <>
            {!auth.isAuthenticated ? (
              <Component />
            ) : (
              <Navigate to={PRICE_CALCULATOR} />
            )}
          </>
        }
      />
    </Routes>
  );
};

export default GuestRoute;
