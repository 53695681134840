import { useState, useRef, useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import classes from './styles.module.scss'
import { useSelector, useDispatch } from 'react-redux'

import DashboardHeader from '../../pages/Dashboard/DashboardHeader'
import { loadUserInfo } from '../../store/actions/authActions'

import * as URLS from '../../Routes/urls'
import { toggle } from '../../store/actions/sidebarActions'
import { setPageTitle } from "../../utils"

// change active icon/tab according to url
const data = [
  {
    value: "DASHBOARD",
    label: "Dashboard",
    href: URLS.DASHBOARD_PAGE,
  },
  {
    value: "TRACK_SHIPMENTS",
    label: "Track Shipments",
    href: URLS.TRACK_SHIPMENT,
  },
  {
    value: "CREATE_SHIPMENTS",
    label: "Create Shipments",
    href: URLS.CREATE_SHIPMENT,
  },
  {
    value: "PRICE_CALCULATORS",
    label: "Price Calculators",
    href: URLS.PRICE_CALCULATOR,
  },
  {
    value: "PROFILE_INFORMATION",
    label: "Profile Information",
    href: URLS.PROFILE_INFORMATION,
  },
  {
    value: "KYC_INFORMATION",
    label: "KYC Information",
    href: URLS.KYC_INFORMATION,
  },
  {
    value: "RECENT_SHIPMENT",
    label: "Recent Shipment",
    href: URLS.RECENT_SHIPMENTS,
  },
  {
    value: "CONTACT_LIST",
    label: "Contact List",
    href: URLS.CONTACT_LIST,
  },

]

export default function LayoutContainer({ children }) {
  const dispatch = useDispatch()
  const mainRef = useRef();
  const activeTab = useSelector(state => state.sidebar.activeTab)

  const [isOpen, setIsOpen] = useState(false)

  const path = window.location.pathname;

  // change active icon/tab according to url
  useEffect(() => {
    data.forEach(d => {
      if (path === d.href) {
        dispatch(toggle(d));
        setPageTitle(d.label)
      }
    })
    // eslint-disable-next-line
  }, [path])

  useEffect(() => {
    dispatch(loadUserInfo())
    //eslint-disable-next-line 
  }, [])

  return (
    <div className={classes.layout}>
      <div className={classes.layout_greenBg}></div>
      <div className={classes.layout_container}>
        <div className={classes.layout_sidebar}>
          <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
        <div ref={mainRef} className={isOpen ? `${classes.layout_main} ${classes.layout_mainOpen}` : classes.layout_main}>
          <div className={classes.dashboard}>
            <DashboardHeader title={activeTab.label} />
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
