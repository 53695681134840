import React from "react";
import { Routes, Route } from "react-router-dom";

import GuestRoute from "./GuestRoute";
import PrivateRoute from "./PrivateRoute";

import * as URLS from "./urls";
import WithSuspense from "../utils/WithSuspense";

const HomePage = React.lazy(() => import("../pages/HomePage/Home"));
const LoginPage = React.lazy(() => import("../pages/LoginPage/Login"));
const RegisterPage = React.lazy(() => import("../pages/RegisterPage/Register"));
const DashboardPage = React.lazy(() =>
  import("../pages/Dashboard/MainDashboard")
);
const TrackShipment = React.lazy(() => import("../pages/TackShipment"));
const CreateShipments = React.lazy(() => import("../pages/CreateShipments"));
const PriceCalculator = React.lazy(() => import("../pages/PriceCalculator"));
const ProfileInfo = React.lazy(() => import("../pages/ProfileInfo"));
const RecentShipment = React.lazy(() => import("../pages/RecentShipment"));
const ContactList = React.lazy(() => import("../pages/ContactList"));

const PaymentStatusPage = React.lazy(() =>
  import("../pages/PaymentStatus/PaymentStatus")
);
const ErrorPage = React.lazy(() => import("../pages/Error"));
const NotAllowed = React.lazy(() => import("../pages/NotAllowed"));
const ResetPassword = React.lazy(() =>
  import("../pages/ResetPassword/ResetPassword")
);
const Kyc = React.lazy(() => import("../pages/kyc/Kyc"));

export default function RoutesComponent() {
  return (
    <Routes>
      <Route
        path={URLS.HOME_PAGE + "*"}
        element={
          <WithSuspense>
            <GuestRoute component={HomePage} />
          </WithSuspense>
        }
      />
      <Route
        path={URLS.LOGIN_PAGE + "/*"}
        element={
          <WithSuspense>
            <GuestRoute component={LoginPage} />
          </WithSuspense>
        }
      />
      <Route
        path={URLS.REGISTER_PAGE + "/*"}
        element={
          <WithSuspense>
            <GuestRoute component={RegisterPage} />
          </WithSuspense>
        }
      />
      <Route
        path={URLS.DASHBOARD_PAGE + "/*"}
        element={
          <WithSuspense>
            <PrivateRoute component={DashboardPage} />
          </WithSuspense>
        }
      />
      <Route
        path={URLS.TRACK_SHIPMENT + "/*"}
        element={
          <WithSuspense>
            <PrivateRoute component={TrackShipment} />
          </WithSuspense>
        }
      />
      <Route
        path={URLS.CREATE_SHIPMENT + "/*"}
        element={
          <WithSuspense>
            <PrivateRoute component={CreateShipments} />
          </WithSuspense>
        }
      />
      <Route
        path={URLS.PRICE_CALCULATOR + "/*"}
        element={
          <WithSuspense>
            <PrivateRoute component={PriceCalculator} />
          </WithSuspense>
        }
      />
      <Route
        path={URLS.PROFILE_INFORMATION + "/*"}
        element={
          <WithSuspense>
            <PrivateRoute component={ProfileInfo} />
          </WithSuspense>
        }
      />
      <Route
        path={URLS.RECENT_SHIPMENTS + "/*"}
        element={
          <WithSuspense>
            <PrivateRoute component={RecentShipment} />
          </WithSuspense>
        }
      />
      <Route
        path={URLS.CONTACT_LIST + "/*"}
        element={
          <WithSuspense>
            <PrivateRoute component={ContactList} />
          </WithSuspense>
        }
      />
      <Route
        path={URLS.PAYMENT_SUCCESS + "/*"}
        element={
          <WithSuspense>
            <PrivateRoute component={PaymentStatusPage} />
          </WithSuspense>
        }
      />
      <Route
        path={URLS.KYC_INFORMATION + "/*"}
        element={
          <WithSuspense>
            <PrivateRoute component={Kyc} />
          </WithSuspense>
        }
      />
      <Route
        path={URLS.PASSWORD_RESET_PAGE}
        element={
          <WithSuspense>
            <ResetPassword />
          </WithSuspense>
        }
      />
      <Route
        path={URLS.NOT_ALLOWED}
        element={
          <WithSuspense>
            <NotAllowed />
          </WithSuspense>
        }
      />
      <Route
        path="*"
        element={
          <WithSuspense>
            <ErrorPage />
          </WithSuspense>
        }
      />
    </Routes>
  );
}
