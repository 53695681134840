import * as Types from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: [],
  errors: {}
}

function contactListReducer(state = initialState, action) {
  switch (action.type) {
    case Types.CONTACT_LIST_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.CONTACT_LIST_LOADED: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }
    case Types.CONTACT_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        data: [],
        errors: action.payload
      };
    }
    default:
      return state;
  }
}

export default contactListReducer;