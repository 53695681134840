import React from "react";
import FullScreenLoading from "../components/Loader/FullScreenLoading";

export default function WithSuspense({ children }) {
  return (
    <React.Suspense fallback={<FullScreenLoading />}>
      {children}
    </React.Suspense>
  );
}
