export const HOME_PAGE = '/'
export const SERVICES_PAGE = '/services'
export const CONTACT_PAGE = '/contact'
export const ABOUT_PAGE = '/about'
export const REGISTER_PAGE = '/register'
export const LOGIN_PAGE = '/login'
export const PASSWORD_RESET_PAGE = "/forget-password/reset/:uid/:token";

export const DASHBOARD_PAGE = '/dashboard'
export const TRACK_SHIPMENT = '/track-shipment'
export const CREATE_SHIPMENT = '/create-shipment'
export const PRICE_CALCULATOR = '/calculate-price'
export const PROFILE_INFORMATION = '/profile'
export const RECENT_SHIPMENTS = '/recent-shipments'
export const CONTACT_LIST = '/contacts'
export const KYC_INFORMATION = "/kyc-information"

export const PAYMENT_SUCCESS = '/payment'
export const NOT_ALLOWED = '/not-allowed'
