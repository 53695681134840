import moment from 'moment'
import { Buffer } from "buffer";

export const timezoneOffset = new Date().getTimezoneOffset()


export function getHeaders(additional) {
  const userToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY)
  const timezoneOffset = new Date().getTimezoneOffset()

  let headers = {
    ...additional,
    "X-Api-Timezone-Offset": timezoneOffset
  }

  if (userToken) {
    headers["Authorization"] = `Token ${userToken}`
  }

  return headers
}

export function createUUID() {
  // used to generate a random UUID (for key props)
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
    return v.toString(16);
  });
}

export const setPageTitle = (title) => {
  const siteName = process.env.REACT_APP_SITE_TITLE;
  if (title) {
    document.title = title + " | " + siteName
  } else {
    document.title = siteName
  }
  return true
}

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result)
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}


export const getUsersFileToken = (user, file_url) => {
  const dateJoinedTimestamp = parseInt(moment(user.date_joined).unix())
  const userToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY)
  if (file_url) {
    try {
      const fileTokenString = `${user.id}##${dateJoinedTimestamp}##${userToken}##${new URL(file_url).pathname}##${createUUID()}`
      const encodedString = Buffer.from(fileTokenString).toString('base64');
      return encodedString    
    }
    catch {
      return ""
    }
  }
}
