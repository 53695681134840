import * as Types from "../actions/actionTypes";

const initialState = {
  loading: false,
  paymentLink: "",
  paymentId: {
    loading: false,
    id: null,
    errors: {}
  },
  order: {
    loading: false,
    success: false,
    data: {},
    errors: ""
  },
  errors: {}
}

function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case Types.PAYMENT_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.PAYMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        paymentLink: action.payload,
        errors: {}
      };
    }
    case Types.PAYMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        paymentLink: "",
        errors: action.payload
      };
    }
    case Types.PAYMENT_ID_LOADING: {
      return {
        ...state,
        paymentId: {
          loading: true
        }
      };
    }
    case Types.PAYMENT_ID_SUCCESS: {
      return {
        ...state,
        paymentId: {
          loading: false,
          id: action.payload.id,
          errors: {}
        }
      };
    }
    case Types.PAYMENT_ID_FAILURE: {
      return {
        ...state,
        paymentId: {
          loading: false,
          id: null,
          errors: action.payload
        }
      };
    }
    case Types.CREATE_SHIPMENT_LOADING: {
      return {
        ...state,
        order: { loading: true }
      };
    }
    case Types.CREATE_SHIPMENT_SUCCESS: {
      return {
        ...state,
        order: {
          loading: false,
          success: true,
          data: action.payload,
          errors:""
        }
      };
    }
    case Types.CREATE_SHIPMENT_FAILURE: {
      return {
        ...state,
        paymentId: {
          loading: false,
          id: null,
        },
        order: {
          success: false,
          data: {},
          errors: action.payload
        },
      };
    }
    default:
      return state;
  }
}

export default paymentReducer;