
import * as Types from "../actions/actionTypes";
import * as URLS from "../../Routes/urls";

import dashboard from "../../assets/sidebar/grey/dashboard.svg"
import dashboardA from "../../assets/sidebar/green/dashboard.svg"


const initialState = {
  data: [
    {
      value: "DASHBOARD",
      label: "Dashboard",
      href: URLS.DASHBOARD_PAGE,
      img: dashboard,
      imgActive: dashboardA
    },
    {
      value: "TRACK_SHIPMENTS",
      label: "Track Shipments",
      href: URLS.TRACK_SHIPMENT,
      img: dashboard,
      imgActive: dashboardA
    },
    {
      value: "CREATE_SHIPMENTS",
      label: "Create Shipments",
      href: URLS.CREATE_SHIPMENT,
      img: dashboard,
      imgActive: dashboardA
    },
    {
      value: "PRICE_CALCULATORS",
      label: "Price Calculators",
      href: URLS.PRICE_CALCULATOR,
      img: dashboard,
      imgActive: dashboardA
    },
    {
      value: "PROFILE_INFORMATION",
      label: "Profile Information",
      href: URLS.PROFILE_INFORMATION,
      img: dashboard,
      imgActive: dashboardA
    },
    {
      value: "KYC_INFORMATION",
      label: "KYC Information",
      href: URLS.KYC_INFORMATION,
      img: dashboard,
      imgActive: dashboardA
    },
    {
      value: "RECENT_SHIPMENT",
      label: "Recent Shipment",
      href: URLS.RECENT_SHIPMENTS,
      img: dashboard,
      imgActive: dashboardA
    },
    {
      value: "CONTACT_LIST",
      label: "Contact List",
      href: URLS.CONTACT_LIST,
      img: dashboard,
      imgActive: dashboardA
    },

  ],
  activeTab: {
    value: "DASHBOARD",
    label: "Dashboard",
    href: URLS.DASHBOARD_PAGE,
  }
}

function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SIDEBAR_CHANGE: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    default:
      return state;
  }
}

export default sidebarReducer;