import * as Types from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: [],
  errors: {},
  add: {
    loading: false,
    success: false,
    errors: {}
  },
  update: {
    loading: false,
    success: false,
    errors: {}
  }
}

function shipperReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SHIPPER_ADD_LOADING: {
      return {
        ...state,
        loading: true,
        add: {
          loading: true,
          success: false,
          errors: {}
        },
      };
    }
    case Types.SHIPPER_ADD_DONE: {
      return {
        ...state,
        loading: false,
        add: {
          loading: false,
          success: true,
          errors: {}
        },
      };
    }
    case Types.SHIPPER_ADD_ERROR: {
      return {
        ...state,
        loading: false,
        add: {
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    }
    case Types.SHIPPER_UPDATE_LOADING: {
      return {
        ...state,
        loading: true,
        update: {
          loading: true,
          success: false,
          errors: {}
        }
      };
    }
    case Types.SHIPPER_UPDATE_DONE: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        update: {
          loading: false,
          success: true,
          errors: {}
        }
      };
    }
    case Types.SHIPPER_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        update: {
          loading: false,
          success: false,
          errors: action.payload,
        }
      };
    }
    case Types.SHIPPER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.SHIPPER_LOADED: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }
    case Types.SHIPPER_ERROR: {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      };
    }
    case Types.SHIPPER_RESET: {
      return {
        ...state,
        add: {
          loading: false,
          success: false,
          errors: {}
        },
        update: {
          loading: false,
          success: false,
          errors: {}
        },
      }
    }
    default:
      return state;
  }
}

export default shipperReducer;
