// auth
export const REGISTER_LOADING = "REGISTER_LOADING"
export const REGISTER_COMPLETED = "REGISTER_COMPLETED"
export const REGISTER_ERROR = "REGISTER_ERROR"
export const REGISTER_RESET = "REGISTER_RESET"

export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";
export const AUTH_LOADING = "AUTH_LOADING"; // for all kind of auth stuffs (login, register, reset password bla bla bla)
export const USER_INFO_LOADED = "USER_INFO_LOADED";
export const USER_INFO_LOAD_ERROR = "USER_INFO_LOAD_ERROR";
export const TOGGLE_FORGOT_PASSWORD = "TOGGLE_FORGOT_PASSWORD";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_STATUS = "RESET_PASSWORD_STATUS";

export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const CHANGE_PASSWORD_ERRORS = "CHANGE_PASSWORD_ERRORS";

export const PROFILE_UPDATE_LOADING = "PROFILE_UPDATE_LOADING";
export const PROFILE_UPDATE_ERRORS = "PROFILE_UPDATE_ERRORS";
export const PROFILE_UPDATED = "PROFILE_UPDATED";

export const PROFILE_RESET = "PROFILE_RESET";

export const SIDEBAR_CHANGE = "SIDEBAR_CHANGE";

// CONTACT LISTS --------------------------------
export const CONTACT_LIST_LOADING = "CONTACT_LIST_LOADING";
export const CONTACT_LIST_LOADED = "CONTACT_LIST_LOADED";
export const CONTACT_LIST_ERROR = "CONTACT_LIST_ERROR";

// SHIPPER 
export const SHIPPER_UPDATE_LOADING = "SHIPPER_UPDATE_LOADING";
export const SHIPPER_UPDATE_DONE = "SHIPPER_UPDATE_DONE";
export const SHIPPER_UPDATE_ERROR = "SHIPPER_UPDATE_ERROR";

export const SHIPPER_ADD_LOADING = "SHIPPER_ADD_LOADING";
export const SHIPPER_ADD_DONE = "SHIPPER_ADD_DONE";
export const SHIPPER_ADD_ERROR = "SHIPPER_ADD_ERROR";

export const SHIPPER_LOADING = "SHIPPER_LOADING";
export const SHIPPER_LOADED = "SHIPPER_LOADED";
export const SHIPPER_ERROR = "SHIPPER_ERROR";
export const SHIPPER_RESET = "SHIPPER_RESET";

// RECEIVER 
export const RECEIVER_UPDATE_LOADING = "RECEIVER_UPDATE_LOADING";
export const RECEIVER_UPDATE_DONE = "RECEIVER_UPDATE_DONE";
export const RECEIVER_UPDATE_ERROR = "RECEIVER_UPDATE_ERROR";
export const RECEIVER_RESET = "RECEIVER_RESET";

export const RECEIVER_ADD_LOADING = "RECEIVER_ADD_LOADING";
export const RECEIVER_ADD_DONE = "RECEIVER_ADD_DONE";
export const RECEIVER_ADD_ERROR = "RECEIVER_ADD_ERROR";

export const RECEIVER_LOADING = "RECEIVER_LOADING";
export const RECEIVER_LOADED = "RECEIVER_LOADED";
export const RECEIVER_ERROR = "RECEIVER_ERROR";

// SHIPMENT
export const SHIPMENT_LOADING = "SHIPMENT_LOADING";
export const SHIPMENT_LOADED = "SHIPMENT_LOADED";
export const SHIPMENT_ERROR = "SHIPMENT_ERROR";

// PRICE CALCULATOR
export const PRICE_CALCULATOR_LOADING = "PRICE_CALCULATOR_LOADING";
export const PRICE_CALCULATOR_LOADED = "PRICE_CALCULATOR_LOADED";
export const PRICE_CALCULATOR_ERROR = "PRICE_CALCULATOR_ERROR";

// PAYMENT
export const PAYMENT_LOADING = "PAYMENT_LOADING"
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS"
export const PAYMENT_FAILURE = "PAYMENT_FAILURE"

export const PAYMENT_ID_LOADING = "PAYMENT_ID_LOADING"
export const PAYMENT_ID_SUCCESS = "PAYMENT_ID_SUCCESS"
export const PAYMENT_ID_FAILURE = "PAYMENT_ID_FAILURE"
export const PAYMENT_ID_REMOVE = "PAYMENT_ID_REMOVE"

export const CREATE_SHIPMENT_LOADING = "CREATE_SHIPMENT_LOADING"
export const CREATE_SHIPMENT_SUCCESS = "CREATE_SHIPMENT_SUCCESS"
export const CREATE_SHIPMENT_FAILURE = "CREATE_SHIPMENT_FAILURE"

export const VALIDATE_SHIPMENT_LOADING = "VALIDATE_SHIPMENT_LOADING"
export const VALIDATE_SHIPMENT_SUCCESS = "VALIDATE_SHIPMENT_SUCCESS"
export const VALIDATE_SHIPMENT_FAILURE = "VALIDATE_SHIPMENT_FAILURE"
export const VALIDATE_SHIPMENT_RESET = "VALIDATE_SHIPMENT_RESET"