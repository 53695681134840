import { useSelector } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import { LOGIN_PAGE } from './urls';
import LayoutContainer from '../components/LayoutContainer/LayoutContainer'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Routes>
      <Route index
        {...rest}
        element={
          <>
            {auth.isAuthenticated ? (
              <LayoutContainer>
                <Component />
              </LayoutContainer>
            ) :
              <Navigate to={LOGIN_PAGE} />
            }
          </>
        }
      />
    </Routes>
  );
};

export default PrivateRoute