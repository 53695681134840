import * as Types from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: [],
  errors: {}
}

function priceCalcReducer(state = initialState, action) {
  switch (action.type) {
    case Types.PRICE_CALCULATOR_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.PRICE_CALCULATOR_LOADED: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {}
      };
    }
    case Types.PRICE_CALCULATOR_ERROR: {
      return {
        ...state,
        loading: false,
        data: [],
        errors: action.payload
      };
    }
    default:
      return state;
  }
}

export default priceCalcReducer;