import * as Types from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: {},
  errors: {},
}

function validateShipmentReducer(state = initialState, action) {
  switch (action.type) {
    case Types.VALIDATE_SHIPMENT_LOADING: {
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };
    }
    case Types.VALIDATE_SHIPMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {}
      };
    }
    case Types.VALIDATE_SHIPMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload
      };
    }
    case Types.VALIDATE_SHIPMENT_RESET: {
      return initialState
    }
    default:
      return state;
  }
}

export default validateShipmentReducer;
