import * as Types from "../actions/actionTypes";

const initialState = {
  isAuthenticated: Boolean(localStorage.getItem(process.env.REACT_APP_TOKEN_KEY)),
  register: {
    loading: false,
    data: {},
    errors: {}
  },
  loginErrors: {},
  logoutErrors: {},
  resetPasswordErrors: {},
  loading: false,
  passwordResetEmailSent: false,
  passwordResetted: false,
  user: {},
  isResetEmailSent: false,
  isForgotPOpened: false,
  isForgotPasswordFailed: false,
  changePassword: {
    loading: false,
    errors: {},
    success: false,
  },
  updateProfile: {
    loading: false,
    errors: {},
    success: false,
  },
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case Types.REGISTER_LOADING: {
      return {
        ...state,
        register: {
          loading: true,
          data: {},
          errors: {}
        },
      };
    }
    case Types.REGISTER_COMPLETED: {
      return {
        ...state,
        register: {
          loading: false,
          data: action.payload,
          errors: {}
        },
      };
    }
    case Types.REGISTER_ERROR: {
      return {
        ...state,
        register: {
          loading: false,
          data: {},
          errors: action.payload
        },
      };
    }
    case Types.REGISTER_RESET: {
      return {
        ...state,
        register: {
          loading: false,
          data: {},
          errors: {}
        },
      };
    }
    case Types.USER_LOGGED_IN: {
      return {
        ...state,
        isAuthenticated: true,
        loginErrors: {},
        user: action.payload ? action.payload : { ...state.user },
      };
    }
    case Types.USER_LOGIN_ERROR: {
      return {
        ...state,
        loginErrors: action.payload,
      };
    }
    case Types.AUTH_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Types.USER_LOGGED_OUT: {
      return {
        ...initialState,
        isAuthenticated: false
      };
    }
    case Types.USER_LOGOUT_ERROR: {
      return {
        ...state,
        logoutErrors: action.payload,
      };
    }
    case Types.USER_FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        isResetEmailSent: action.payload,
      };
    }
    case Types.TOGGLE_FORGOT_PASSWORD: {
      return {
        ...state,
        isForgotPOpened: action.payload,
      };
    }
    case Types.FORGOT_PASSWORD_FAIL: {
      return {
        ...state,
        isForgotPasswordFailed: action.payload,
      };
    }
    case Types.RESET_PASSWORD_ERROR: {
      return {
        ...state,
        resetPasswordErrors: action.payload,
        passwordResetted: false,
      };
    }
    case Types.RESET_PASSWORD_STATUS: {
      return {
        ...state,
        passwordResetted: action.payload,
        resetPasswordErrors: {},
      };
    }
    case Types.CHANGE_PASSWORD_LOADING: {
      return {
        ...state,
        changePassword: {
          loading: true,
          success: false,
          errors: {},
        },
      };
    }
    case Types.CHANGE_PASSWORD_ERRORS: {
      return {
        ...state,
        changePassword: {
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    }
    case Types.PASSWORD_CHANGED: {
      return {
        ...state,
        changePassword: {
          loading: false,
          success: true,
          errors: {},
        },
      };
    }
    case Types.PROFILE_UPDATE_LOADING: {
      return {
        ...state,
        loading: true,
        updateProfile: {
          loading: true,
          success: false,
          errors: {},
        },
      };
    }
    case Types.PROFILE_UPDATE_ERRORS: {
      return {
        ...state,
        updateProfile: {
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    }
    case Types.PROFILE_UPDATED: {
      return {
        ...state,
        user: action.payload,
        updateProfile: {
          loading: false,
          success: true,
          errors: {},
        },
      };
    }
    case Types.PROFILE_RESET: {
      return {
        ...state,
        loading: false,
        passwordResetEmailSent: false,
        passwordResetted: false,
        isResetEmailSent: false,
        isForgotPOpened: false,
        isForgotPasswordFailed: false,
        changePassword: {
          loading: false,
          errors: {},
          success: false,
        },
        updateProfile: {
          loading: false,
          errors: {},
          success: false,
        },
      }
    }
    default:
      return state;
  }
}

export default authReducer;
