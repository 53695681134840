import { useEffect, useState } from 'react'
import classes from './styles.module.scss'
import { useSelector } from 'react-redux'

export default function DashboardHeader({ title }) {
  const user = useSelector(state => state.auth?.user)

  const [initials, setInitials] = useState("");

  useEffect(() => {
    setInitials("")
    user?.first_name?.split(" ").forEach(u =>
      setInitials(prev => prev + u.slice(0, 1)))
  }, [user?.first_name])

  return (
    <div className={classes.dashboard_header}>
      <h2>{title}</h2>
      <div className={classes.dashboard_header_user}>
        <div className={classes.dashboard_header_user_avatar}>
          {initials}
        </div>
        <div className={classes.dashboard_header_user_name}>
          {user?.first_name}
        </div>
      </div>
    </div>)
}
