import React from 'react'
import classes from './styles.module.scss'
import { Link } from 'react-router-dom'
import { createUUID } from '../../utils'
import { useSelector, useDispatch } from 'react-redux'
import { toggle } from '../../store/actions/sidebarActions'

export default function ItemGroup({ groupTitle, elements, isOpen }) {
  const sidebarToggle = useSelector(state => state.sidebar)
  const dispatch = useDispatch();

  return (
    <div
      style={isOpen ? {}
        : {
          alignItems: 'center',
          margin: "1rem 3rem"
        }}
      className={classes.sidebar_itemGroup}>
      {isOpen &&
        <div className={classes.sidebar_itemGroup_title}>
          {groupTitle}
        </div>}
      {elements.map(element =>
        <Link
          to={element.href}
          key={createUUID()}
          style={!isOpen ? {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          } : {}}
          className={
            sidebarToggle.activeTab.value === element.value
              ? `${classes.sidebar_itemGroup_item} ${classes.sidebar_itemGroup_itemActive}`
              : classes.sidebar_itemGroup_item
          }
          onClick={() => {
            dispatch(toggle(element))
          }}
        >
          {
            isOpen ?
              <img
                style={isOpen ? {} : {
                  width: "30px",
                  margin: "1rem 0"
                }}
                src={sidebarToggle.activeTab.value === element.value
                  ? element.imgActive
                  : element.img}
                alt=""
              />
              :
              <div>
                <img
                  src={sidebarToggle.activeTab.value === element.value
                    ? element.imgActive
                    : element.img}
                  alt=""
                />
              </div>
          }
          {isOpen ? element.label : ""}
        </Link>
      )}

    </div>)
}
