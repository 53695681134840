import * as Types from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: [],
  errors: {},
  add: {
    loading: false,
    success: false,
    errors: {}
  },
  update: {
    loading: false,
    success: false,
    errors: {}
  },
}

function receiverReducer(state = initialState, action) {
  switch (action.type) {
    case Types.RECEIVER_ADD_LOADING: {
      return {
        ...state,
        loading: true,
        add: {
          loading: true,
          success: false,
          errors: {}
        },
      };
    }
    case Types.RECEIVER_ADD_DONE: {
      return {
        ...state,
        loading: false,
        add: {
          loading: false,
          success: true,
          errors: {}
        },
      };
    }
    case Types.RECEIVER_ADD_ERROR: {
      return {
        ...state,
        loading: false,
        add: {
          loading: false,
          success: false,
          errors: action.payload,
        },
      };
    }
    case Types.RECEIVER_RESET: {
      return {
        ...state,
        add: {
          loading: false,
          success: false,
          errors: {}
        },
        update: {
          loading: false,
          success: false,
          errors: {}
        },
      }
    }
    case Types.RECEIVER_UPDATE_LOADING: {
      return {
        ...state,
        loading: true,
        update: {
          loading: true,
          success: false,
          errors: {}
        }
      };
    }
    case Types.RECEIVER_UPDATE_DONE: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        update: {
          loading: false,
          success: true,
          errors: {}
        }
      };
    }
    case Types.RECEIVER_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        update: {
          loading: false,
          success: false,
          errors: action.payload,
        }
      };
    }
    case Types.RECEIVER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.RECEIVER_LOADED: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }
    case Types.RECEIVER_ERROR: {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      };
    }
    default:
      return state;
  }
}

export default receiverReducer;
