import * as Types from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: [],
  errors: {},
}

function shipmentReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SHIPMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.SHIPMENT_LOADED: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }
    case Types.SHIPMENT_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }
    default:
      return state;
  }
}

export default shipmentReducer;
