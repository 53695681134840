import { combineReducers } from "redux";
import authReducer from "./authReducer";
import sidebarReducer from "./sidebarReducer";
import contactListReducer from "./contactListReducer";
import shipperReducer from "./shipperReducer";
import receiverReducer from "./receiverReducer";
import shipmentReducer from "./shipmentReducer";
import priceCalReducer from "./priceCalReducer";
import paymentReducer from "./paymentReducer";
import validateShipmentReducer from "./validateShipmentReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
  contactList: contactListReducer,
  shipper: shipperReducer,
  receiver: receiverReducer,
  shipment: shipmentReducer,
  priceCalc: priceCalReducer,
  payment: paymentReducer,
  validateShipmentData: validateShipmentReducer
});

export default rootReducer;
