import classes from './styles.module.scss'
import { createUUID } from '../../utils'
import { useEffect, useState } from 'react'

import ItemGroup from './ItemGroup'

import burgerIcon from '../../assets/icons/menu.png'
import logo from '../../assets/images/logo.png'
import logoutIcon from '../../assets/icons/logout.png'

import { logout } from "../../store/actions/authActions"
import { useDispatch, useSelector } from 'react-redux'
import * as URLS from "../../Routes/urls";

import dashboard from "../../assets/sidebar/grey/dashboard.svg"
import dashboardA from "../../assets/sidebar/green/dashboard.svg"

import track from "../../assets/sidebar/grey/track.svg"
import trackA from "../../assets/sidebar/green/track.svg"

import create from "../../assets/sidebar/grey/create.svg"
import createA from "../../assets/sidebar/green/create.svg"

import price from "../../assets/sidebar/grey/price.svg"
import priceA from "../../assets/sidebar/green/price.svg"

import profile from "../../assets/sidebar/grey/profile.svg"
import profileA from "../../assets/sidebar/green/profile.svg"

import kyc from "../../assets/sidebar/grey/kyc.png"
import kycA from "../../assets/sidebar/green/kyc.png"

import recent from "../../assets/sidebar/grey/recent.svg"
import recentA from "../../assets/sidebar/green/recent.svg"

import contact from "../../assets/sidebar/grey/contact.svg"
import contactA from "../../assets/sidebar/green/contact.svg"

import signout from "../../assets/icons/signout.png"

const sidebarItems = [
  {
    groupTitle: "Overview",
    elements: [
      {
        value: "DASHBOARD",
        label: "Dashboard",
        href: URLS.DASHBOARD_PAGE,
        img: dashboard,
        imgActive: dashboardA
      },
    ]
  },
  {
    groupTitle: "Shipment",
    elements: [
      {
        value: "TRACK_SHIPMENTS",
        label: "Track Shipments",
        href: URLS.TRACK_SHIPMENT,
        img: track,
        imgActive: trackA
      },
      {
        value: "CREATE_SHIPMENTS",
        label: "Create Shipments",
        href: URLS.CREATE_SHIPMENT,
        img: create,
        imgActive: createA
      },
    ]
  },
  {
    groupTitle: "Calculators",
    elements: [
      {
        value: "PRICE_CALCULATORS",
        label: "Price Calculators",
        href: URLS.PRICE_CALCULATOR,
        img: price,
        imgActive: priceA
      },
    ]
  },
  {
    groupTitle: "My Profile",
    elements: [
      {
        value: "PROFILE_INFORMATION",
        label: "Profile Information",
        href: URLS.PROFILE_INFORMATION,
        img: profile,
        imgActive: profileA
      },
      {
        value: "RECENT_SHIPMENT",
        label: "Recent Shipment",
        href: URLS.RECENT_SHIPMENTS,
        img: recent,
        imgActive: recentA
      },
      {
        value: "CONTACT_LIST",
        label: "Contact List",
        href: URLS.CONTACT_LIST,
        img: contact,
        imgActive: contactA
      },
    ]
  },
]
const sidebarItemsIndia = [
  {
    groupTitle: "Overview",
    elements: [
      {
        value: "DASHBOARD",
        label: "Dashboard",
        href: URLS.DASHBOARD_PAGE,
        img: dashboard,
        imgActive: dashboardA
      },
    ]
  },
  {
    groupTitle: "Shipment",
    elements: [
      {
        value: "TRACK_SHIPMENTS",
        label: "Track Shipments",
        href: URLS.TRACK_SHIPMENT,
        img: track,
        imgActive: trackA
      },
      {
        value: "CREATE_SHIPMENTS",
        label: "Create Shipments",
        href: URLS.CREATE_SHIPMENT,
        img: create,
        imgActive: createA
      },
    ]
  },
  {
    groupTitle: "Calculators",
    elements: [
      {
        value: "PRICE_CALCULATORS",
        label: "Price Calculators",
        href: URLS.PRICE_CALCULATOR,
        img: price,
        imgActive: priceA
      },
    ]
  },
  {
    groupTitle: "My Profile",
    elements: [
      {
        value: "PROFILE_INFORMATION",
        label: "Profile Information",
        href: URLS.PROFILE_INFORMATION,
        img: profile,
        imgActive: profileA
      },
      {
        value: "KYC_INFORMATION",
        label: "KYC Information",
        href: URLS.KYC_INFORMATION,
        img: kyc,
        imgActive: kycA,
      },
      {
        value: "RECENT_SHIPMENT",
        label: "Recent Shipment",
        href: URLS.RECENT_SHIPMENTS,
        img: recent,
        imgActive: recentA
      },
      {
        value: "CONTACT_LIST",
        label: "Contact List",
        href: URLS.CONTACT_LIST,
        img: contact,
        imgActive: contactA
      },
    ]
  },
]

const NavHeader = ({ toggleSidebar, isOpen }) => (
  <header className={classes.sidebar_header}>
    <button type="button" onClick={toggleSidebar}>
      <img src={burgerIcon} alt="Menu" />
    </button>

    {isOpen &&
      <img className={classes.sidebar_header_logo} src={logo} alt="Logo" />
    }
  </header>
)

export default function Sidebar({ isOpen, setIsOpen }) {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const [loopItem, setLoopItem] = useState(sidebarItems)

  useEffect(() => {
    if (!auth.loading && "country" in auth.user) {
      if (auth.user.country === "India") {
        setLoopItem(sidebarItemsIndia)
      }
    }
  }, [auth])

  function toggleSidebar() {
    setIsOpen(!isOpen)
  }

  function handleLogout() {
    dispatch(logout())
  }

  return (
    <aside>
      {!isOpen && <button className={classes.burgerBtn} type="button" onClick={toggleSidebar}>
        <img src={burgerIcon} alt="Menu" />
      </button>}
      <aside className={isOpen ? `${classes.sidebar} ${classes.sidebarOpen}` : classes.sidebar}>
        <nav className={classes.sidebar_nav}>
          <NavHeader toggleSidebar={toggleSidebar} isOpen={isOpen} />
          <div className={classes.sidebar_items}>
            {loopItem.map(e =>
              <ItemGroup
                key={createUUID()}
                className={classes}
                groupTitle={e.groupTitle}
                elements={e.elements}
                isOpen={isOpen} />
            )}
            {isOpen ?
              <img onClick={handleLogout} className={classes.sidebar_logout} src={isOpen ? logoutIcon : signout} alt="Logout" />
              :
              <div
                className={classes.sidebar_smallItem}>
                <img onClick={handleLogout} src={isOpen ? logoutIcon : signout} alt="Logout" />
              </div>
            }
          </div>
        </nav>
      </aside>
    </aside>
  )
}
